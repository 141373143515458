
.Contact_space{
    height: 500px;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
}

.Contact_text{
    text-align: center;
    font-size: 30px;
    margin-top: 50px;
    width: 60%;
}

.Contact_infos{
    text-align: center;
    font-size: 32px;
    margin-top: 30px;
}

.Contact_reseaux{
    font-size: 32px;
    text-align: center;
    height: 180px;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.Contact_reseau{
    margin-left: 10px;
    font-size: 50px;
    color: #312c2a;
    cursor: grab;
}

.TextMenu_Contact{
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-family: 'Asong';
    font-size: 80px;
    margin-top: 260px;
  
}

.infos_add{
    margin: inherit;
    font-size: 30px;
    font-family: Helvetica;
    margin-top: 30px;
}

@media screen and (min-width: 320px) and (max-width: 799px) {

    .TextMenu_Contact{
        height: 130px;
        margin-top: 0;
    }

    .Contact_space{
        height: auto;
        font-size: x-large;
        margin-top: 10px;
    }

    .Contact_text{
        align-self: center;
        width: 88%;
        font-size: 25px;
        margin-top: 0;
    }

    .Contact_infos{
        align-self: center;
        width: 88%;
        font-size: 25px;
    }

    .Contact_reseaux{
        height: auto;
        margin-top: 60px;
        font-size:x-large;
        font-weight: 500;
    }

    .Contact_reseau{
        margin-top: 10px;
    }

    .infos_add{
        width: 90%;
        align-self: center;
        font-size:25px;
        text-wrap: balance;
        margin-bottom: 40px;
    }

}


@media screen and (min-width: 1450px) {
    .TextMenu_Contact{
        width: auto;
    }
    
    .Contact_space{
        width: auto;
    }


    }