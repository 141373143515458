.Module_Menu{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    
}

.Backgd_Img{
    background-image: url("../Assets/salle.jpg");
    background-size: cover;
    background-position-y: bottom;
    height: 1000px;
    display: grid;
}

.Formule{
    background: beige;
    border-radius: 20px;
    height: 650px;
    width: 80%;
    margin:auto;
    text-align: -webkit-center;
}

.Title_space{
    text-align: center;
}

.Title_Text{
    font-size: xx-large;
    margin-top: 40px;
    margin-bottom: 45px;
    width: 90%;
    display: inline-block;
    font-family: 'Asong';
    font-style: italic;
    font-size: 60px;
}

.TextMenu{
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: brown;
    color: white;
    font-family: 'Asong';
    font-size: 65px;
}

.Title_CTA{
    font-family: helvetica;
    letter-spacing: -1px;
    text-decoration: overline;
    font-weight: 300;
    font-size: 25px;
    display: inline-block;
}

.Menu_space{
    display: flex;
    margin-top: 50px;
    justify-content: space-evenly;
    height: 300px;
    font-family: helvetica;
}

.Menu_Text{
    width: 10%;
    display: flex;
    text-align-last: center;
    font-size: xx-large;
    align-items: center;
    text-align: -webkit-center;
}

.Border_Text{
    border-right: 5px solid black;
    height: 200px;
    align-self: center;
}

.Menu_CTA{
    width: 60%;
    text-align: center;
    font-size: 25px;
}

@media screen and (min-width: 320px) and (max-width: 799px) {

.Module_Menu{
    width: 100%;
}

.TextMenu{
    width: 100%;
    text-align: center;
}

.Formule{
    height: auto;
    width: 90%;
    margin: 20px;
}

.Backgd_Img{
    height: auto;

}

.Border_Text{
    border-right: 7px solid black;
    height: 310px;
}

.Title_Text{
    width: 100%;
    font-size: 55px;
}

.Title_CTA{
    font-size: 27px;
    width: 60%;
}

.Menu_Text{
    font-size: 25px;
    width: 40%;
}

.Menu_space{
    height: auto;
    margin-top: 0;
    margin-top: 40px;
    margin-bottom: 40px;
}

.Border_Text{
    border-right: 3px solid black;
    height: 100px;
}

.Module_Menu{
    height: auto;
}

.Menu_CTA{
    width: 70%;
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
}

}

@media screen and (min-width: 1450px) {

    .Module_Menu{
        width: auto;
    }

    .Formule{
        width: 80%;
        height: 850px;
    }

    .Title_Text{
        font-size: 90px;
    }

    .Title_CTA{
        font-size: 45px;
        letter-spacing: normal;
    }

    .TextMenu{
        font-size: 100px;
        width: auto;
    }

    .Menu_Text{
        font-size: 40px;
    }

    .Menu_CTA{
        font-size: 30px;
        width: 75%;

    }

    .Menu_space{
        margin-bottom: 60px;
    }

    .Border_Text{
        height: 260px;
        margin-top: 30px;

    }
}
