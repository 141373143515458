
.Backgd_Img2{
    background-image: url("../Assets/Exterieur.jpg");
    background-size: cover;
    background-position-y: bottom;
    display: grid;
    width: 100%;
}

.Formule2{
    background: beige;
    text-align: -webkit-center;
    width: 100%;
}

.Title_Text_Soir{
    font-size: xx-large;
    margin-top: 80px;
    margin-bottom: 60px;
    font-family: 'Asong';
    font-style: italic;
    font-size: 60px;
}

.Menu_space_Soir{
    display: flex;
    margin: auto;
    justify-content: space-evenly;
    height: auto;
    width: 100%;
   
}

.Menu_Text2_Soir{
    height: 550px;
    font-size: larger;
    text-align: start;
    line-height: 0.7cm;
    display: flex;
    width: 100%;
}

.Border_Text_Soir{
    border-right: 5px solid black;
    height: 1100px;
    align-self: center;
    margin-top: 60px;
}

.Menu_CTA_Soir{
    margin-top: 120px;
    width: 100%;
    text-align: center;
    font-size: 25px;
    margin-bottom: 50px;
}

.Liste_Soir{
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
}

li{
    list-style-type: none;
}

span{
    display: inline-block;
    width: 80%;
    font-size: 25px;
    margin-bottom: 15px;
}

h3{
    font-size: 30px;
}

.Price{
    display: inline-block;
    float: right;
    font-size: 20px;
}

.Menu_Offres{
    width: 100%;
}

.Menu_Offres_Space{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Menu_Offres_Text2{
    font-size: 25px;
    letter-spacing:  3px;
    line-height: 40px;
    width: 40%;
}

.Menu_Title_Offre{
    margin: 0;
}

.Price2 {
    font-size: 24px;
    font-weight: 600;
    padding-left: 40%;
    position: absolute;
}

.Menu_Flex{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.Menudusoir{
    width: 40%;
}

.menu{
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 60px;
    width: 100%;
}

@media screen and (min-width: 320px) and (max-width: 799px) {

    .Backgd_Img2{
        height: auto;
    }

    .Formule2{
        height: auto;
    }

    .Menu_CTA_Soir{
        margin-top: 0;
        width: 60%;
    }

    h3{
        font-size: 21px;
        font-weight: 700;
    }

    span{
        font-size: 20px;
        width: 75%;
    }

    .Menu_Text2_Soir{
        height: auto;
        
    }

    .Border_Text_Soir{
        height: auto;
    }

    .Menu_space_Soir{
        height: auto;
        margin-bottom: 0;
    }

    .Price2{
        padding-left: 80%;
        font-size: 20px;
    }

    .Menu_Offres_Text2{
        letter-spacing:normal;
        line-height: normal;
        width: 60%;
    }

    .Menu_Text2_Soir{
        height: auto;
    }

    .Price{
        font-weight: 400;
        font-size: 17px;
    }
 }
    
 @media screen and (min-width: 1450px) {

    .Liste_Soir{
        width: 600px;
    }

    .Menu_Text2_Soir{
        font-size: 30px;
        height: 600px;
        line-height: normal;
    }

    span{
        font-size: 30px;
        width: 80%;
    }

    .Title_Text_Soir{
        font-size: 100px;
    }

    .Border_Text_Soir{
        height: 1200px;
    }

    h3{
        font-size: 46px;
    }

    .Price2{
        font-size: 35px;
    }

    .Menu_Offres_Text2{
        font-size: 40px;
        line-height: normal;
        width: 550px;
    }

    .Menu_CTA_Soir{
        font-size: 40px;
    }

 }