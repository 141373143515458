
.photos_space{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-flow: wrap-reverse;
}

.size{
    width: 40%;
    margin: 20px;
    height: auto;
    overflow: hidden;
}

.box{
    height: 400px;
}

.pics_size{
    object-fit: cover;
    width: 100%;
    height: 450px;
}

.uno{
    order: 1;
}

.tres{
    order: 3;
}

.photos_position{
    height: 400px;
    margin: 10px;
    overflow: hidden;
    width: 40%;
    display: inline-block;
}

@media screen and (min-width: 320px) and (max-width: 799px) {

.photos_space{
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    width: auto;
    margin-top: 15px;
    align-content: center;
}

.pics_size{
    object-fit: cover;
    width: 100%;
    height: 300px;
}

.size{
    width: 100%;
    margin-bottom: 15px;
    height:auto;
    margin: 0;
    text-align: -webkit-center;
    }

.photos_position{
    width: auto;
    height: auto;
}

}

@media screen and (min-width: 1450px) {
    .size{
        text-align-last: center;
        font-size: 22px;
    }


}
