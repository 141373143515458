
.Traiteur_Space{
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin: 20px;
    background-color: white;
    border: 1px darkgrey solid;
    border-radius: 20px;
    padding-bottom: 20px;
}


.traiteur_info_text{
    display: flex;
}

.traiteur_TakeAway{
    height: auto;
    margin-top:220px;
    padding-bottom:50px;
    background-color:#372929;
}

.traiteur_info_{
    display: flex;
    margin: 20px;
}

.traiteur_info_title{
    font-size: 35px;
    width: 100%;
    font-weight: 600;
    text-align-last: center;
    padding-top: 10px;
}

.traiteur_pics_size{
    border-radius: 10px;
    object-fit: cover;
    width: 99%;
    height: inherit;  
}

.traiteur_intro{
    text-align: justify;
    font-size: 22px;
}

.Dessert{
    width: 60%;
    right: -70px;
    position: fixed;
}

.traiteur_buffet_text{
    width: 100%;
    text-align: -webkit-center;
    padding-top: 10px;
}

.price_space{
    width: 100%;
    text-align: center;
}

.Module_Intro_Traiteur{  
    text-align: center;
    height: 150px;
    margin-top: 100px;

}

.TextMenu_Traiteur{
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: 'Asong';
    font-size: 85px;
}


.price_pics{
    font-size: 22px;
    padding: 10px;
}

.pics_place{
    display: flex;
}

/* Boissons */

.style{
    font-size:larger;
    width: 90%;
    text-wrap: balance;
}

.style_space{
    display: flex;
    justify-content: space-between;
}

.Bever{
    border-right: 5px solid black;
    height: 700px;
    margin-top: 20px;
}

.separator{
    margin-top: 10px; 
    border-bottom: 1px solid #ccc; 
    width: 70%;
}

.beverages{
    text-align: -webkit-auto;
    height: auto;
    margin-bottom: 50px;
}

.bever_space{
    width: 45%;
}

.wine{
    font-size: 25px;
    text-decoration-line: underline;
    display: flex;
    align-items: center; 
    place-content: center;
}

.space{
    width: 80%;
    font-size: 15px;
}

@media screen and (min-width: 320px) and (max-width: 799px) {

    .Traiteur_Space{
        height: auto;
    }

    .traiteur_pics_size{
        width: 90%;
        height: 250px;
    }

    .traiteur_info_{
        display: inline-block;
        margin: 0;
        padding: 15px;
        text-align: -webkit-center;
    }

    .Traiteur_photos_space{
        display: flex;
        height: auto;
    }

    .traiteur_pics_text{
        height: auto;
    }

    .traiteur_TakeAway{
        height: auto;
        margin-top: 0;
        
    }

    .traiteur_buffet_text{
        width: 80%;
        font-size:larger;
    }

    .price{
        width: 80%;
    }

    .price_infos{
        margin-top: 10px;
        margin-bottom: 20px;
        text-wrap: balance;
        text-align: center;
        font-size: 25px;
    }

    .pics_place{
        display: inline-block;
        width: 100%;
    }

    .bever_space{
        width: 90%;
    }

    .bever_phone{
        display: inline-block;
        padding-bottom: 30px;
    }

    .Bever{
        height: auto;
    }

    .beverages{
        height: auto;
    }

    .phone{
        width: 80%;
        text-align: center;
    }

    .space{
        font-size: 20px;
        width: 90%;
        padding-bottom: 15px;
    }

 
}


@media screen and (min-width: 1450px) {
.TextMenu_Traiteur{
    width: auto;
}

.price{
    font-size: 25px;
}

.traiteur_pics_text{
    width: 100%;
}

.bever_space{
    width: 45%;
}


}