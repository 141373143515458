
.Module_1{
    width: 100%;
   height: 500px;
   margin-top: 100px;

}

.Module_Bandeau{
    background-color: darkgray;
    height: 300px;
    width: 100%;
    display: flex;
    place-items: center;

}

.Module_title{
    font-family: 'Asong';
    font-size: 85px;
    width: 100%;
    font-weight: lighter;
    margin-top: 300px;
}

.Module{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
}

.Module_pres{
    width: 45%;
    border-radius: 15px;
    contain: content;
    margin-left: 20px;
}

.Module_photos{
    width: 100%;
    height: 100%;
}

.Dessert{
    width: 70%;
    top: -80px;
    right: -70px;
    position: fixed;

}

.Module_text{
    width: 80%;
    font-size: 25px;
    align-self: center;
    text-align: -webkit-center;
}

.Module_description{
    text-align: justify;
   width: 70%;
}

.Module_intro{
    text-align: center;
    height: 150px;
    width: 100%;
}

@media screen and (min-width: 320px) and (max-width: 799px) {
    .Dessert{
        width:100%;
        top:-290px;
        right:-70px;
    }

    .Module_pres{
        border-radius: 50%;
        margin-bottom: 20px;
        width: 80%;
    }

    .Module{
        text-align: -webkit-center;
        display: inline-block;
        margin-top: 20px;
    }

    .Module_1{
        margin-top: 0px;
        margin-bottom: 0px;
        width: 100%;
        height: auto;
    }

    .Module_description{
        margin-bottom: 20px;
        text-align: center;
        font-weight: 300;
    }

    .Module_title{
        width: 90%;
        text-align: center;
        margin-top: 0;
        font-size: 70px;
    }

    .Module_Bandeau{
        height: auto;
        display: inline-block;

    }

    .Module_text{
        width: 90%;
        height: auto;
        margin-bottom: 20px;
        display: inline-block;
        font-size: 25px;
    }

    .Module_intro{
    height: auto;
    width: 100%;
    margin-bottom: 40px;
    text-align: -webkit-center;
    }
}

@media screen and (min-width: 1450px) {

    .Module_intro{
        margin-top: 160px;
    }

    .Module_1{
        margin-top: 100px;
        width: auto;
    }
    .Module_text{
        font-size: 30px;
        height: 450px;
        align-content: center;
    }

    .Module_Bandeau{
        height: 400px;
    }
 
    .Module_pres{
        width: 40%;
    }



}