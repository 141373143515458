@font-face {
    font-family: 'Aireads';
    src: url('../Polices/Aireads.otf') format('opentype');
  }
  
@font-face {
    font-family: 'Chalk';
    src: url('../Polices/Chalk.otf') format('opentype');
  }

@font-face{
    font-family: 'Asong' ;
    src: url('../Polices/Asong.ttf') format('truetype')
}
  
.Body{
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
}

.HeaderBanner{
    width: 100%;
    position: relative;
}

.LogoBanner{
    display: flex;
    justify-content: center;
}

.Image{
    background-image: url("../Assets/dessert3.webp"); 
    width: 100%;
    background-size: cover;
    height: 1050px;
    top: -400px;
    filter: opacity(0.8);
    position: absolute;
    background-attachment:fixed;
    background-position: center;
}

.Logo{
    width: 320px;
    height: 320px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: appear 2s forwards;
}

.NavBanner{
    display: flex;
    width:100%;
    justify-content: space-evenly;
    background-color: #881812;
    height: 60px;
    align-items: center;
    position: absolute;
    margin-top: 160px;
}

.SpaceBanner{
    margin-top: 450px;
    width: 100%;
}

.Link{
    color: white;
    font-family: 'Asong';
    text-decoration: none;
    font-size: 40px;
    cursor: grab;
    opacity: 0;
    transform: transform 0.3 ease; 
    animation: appear 1s forwards;
}

.Link.animate {
    animation: fadeInUp 1.5s ease forwards;
  }
  
@keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

@keyframes appear {
    to {
        opacity: 1; 
    }
}

.link-1 {
    animation-delay: 0s;
  }
  .link-2 {
    animation-delay: 0.3s;
  }
  .link-3 {
    animation-delay: 0.6s;
  }
  .link-4 {
    animation-delay: 0.9s;
  }
  .link-5{
    animation-delay: 1s;
  }

@media screen and (min-width: 320px) and (max-width: 899px) {

    .Body{
    height: auto;
    width: 100%;
    }

   .Image{
    height: -webkit-fill-available;
   }

   .HeaderBanner{
    height: 600px;
    width: 100%;
   }

   .SpaceBanner{
     margin-top: 0px;
   }

   .NavBanner{
    height: 80px;
    margin-top: 0;
    width: 100%;
   }

   .Link{
    font-size: 30px;
   }
}

   @media screen and (min-width: 1450px) {
    .Image{
        background-attachment: initial;
    }

    .NavBanner{
        margin-top: 150px;
        height: 80px;
    }

    .LogoBanner{
        width: auto;
    }
    .SpaceBanner{
        margin-top: 500px;
    }

    .Link{
        font-size: 60px;
    }


}