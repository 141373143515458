.footerBanner{
    width: 100%;
    height: 300px;
    background: black;
    display: flex;
    justify-content: center;
    /* margin-top: 40px; */
}

.footerElement{
    display: flex;
    align-self: center;
    align-items: center;
}

.logo_space{

}

.picture{
    width: 180px;
    height: 100%;
    padding-left: 30px ;
    padding-right: 40px;
}

.textFooter{
    color: white;
    font-size: x-large;
    text-align: center;
}


@media screen and (min-width: 320px) and (max-width: 799px) {
    .footerBanner{
        width: 100%;
        height: auto;
        margin-top: 0;
        display: inline-block;
    }

    .footerElement{
        flex-direction: row;
        margin-top: 30px;
        margin-bottom: 30px;
        padding-right: 10px;
        justify-content: center;
    }

    .picture{
        width: 120px;
        padding-left: 0;
        padding-right: 20px;
    }

    .textFooter{
        font-size: 70px; 
    }

    .infotop{
        width: 95%;
        font-size: 20px;
        margin-left: 10px;
        text-wrap: balance;
    }

    .infocenter{
        margin-top: 10px;
        font-size: 15px;
    }

    .infophone{
        font-size: large;
        padding-top: 10px;
        font-weight: 500;
    }

    .infoend{
        margin-top: 10px;
        font-size: 13px;
    }
}

@media screen and (min-width: 1450px) {
    .footerBanner{
        height: auto;
    }

    .picture{
        margin-top: 20px;
    }

    .infoFooter{
        font-size: 40px;
    }


}